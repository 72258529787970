import { Button, TextBox } from '@itsrever/design-system'
import { useTranslate } from '@/hooks'
import { useState } from 'react'
import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import ProductPreview from '@/components/ProductPreview'

interface SelectExchangeOpenProps {
    product: ModelsLineItem
    handleContinue: () => void
}

export const SelectExchangeOpen: React.FC<SelectExchangeOpenProps> = ({
    product,
    handleContinue
}) => {
    const { translate } = useTranslate()
    const [userRequest, setUserRequest] = useState('')

    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <div
                id="productPreview"
                className="mt-4 flex max-w-full border-b border-solid border-grey-3 py-4"
            >
                <ProductPreview product={product} />
            </div>
            <div className="px-2">
                <h6
                    className="my-4"
                    dangerouslySetInnerHTML={{
                        __html: translate('select_exchange_old')
                    }}
                />
                <div className="flex w-full flex-col content-center items-center">
                    <TextBox
                        placeholder={translate(
                            'select_exchange_variants_placeholder'
                        )}
                        value={userRequest}
                        onChange={(e) => setUserRequest(e.target.value)}
                    />
                </div>
                <div className="mt-4 flex w-full flex-col content-center items-center pb-2 lg:mt-8">
                    <Button disabled={!userRequest} onClick={handleContinue}>
                        {translate('select_exchange_button_continue')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
