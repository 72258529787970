import posthog from 'posthog-js'

const posthogApiKey = import.meta.env.VITE_PUBLIC_POSTHOG_KEY

export function initializePosthog() {
    posthog.init(posthogApiKey, {
        api_host: 'https://hotdog.byrever.com',
        disable_session_recording: true,
        autocapture: false
    })
}

export function setUserPosthog(
    email: string | undefined,
    firstName?: string | undefined,
    lastName?: string | undefined
) {
    if (!email) return
    posthog?.identify(email, {
        $email: email,
        $first_name: firstName,
        $last_name: lastName
    })
}

export function captureEventPosthog(
    eventName: string,
    properties?: Record<string, unknown>
) {
    posthog?.capture(eventName, properties)
}

export function isPosthogFFEnabled(featureFlag: string) {
    return posthog?.isFeatureEnabled(featureFlag)
}
