import { Fragment } from 'react'
import styled from 'styled-components'
import ProgressBar from '@/layout/LayoutComponents/ProgressBar'
import { useTranslate, useNavigate, usePages, useRefundMethods } from '@/hooks'
import { useTheme } from '@itsrever/design-system'
import { useAppSelector } from '@/redux/hooks'
import { getDate } from '@/utils'
import { useTranslation } from 'react-i18next'
import { Button } from '@itsrever/design-system'
import {
    NonReturnableProductCard,
    OrderItem
} from '@/components/ItemSelectionComponents'
import { Subproduct } from '@/redux/order/orderSlice'
import { ItemActions, RefundActions } from '@/redux/return/returnSlice'
import { LoadingModal } from '../Loading'
import { usePostHog } from '@/application/use-posthog'

export const Order: React.FC = () => {
    const theme = useTheme()
    const { translate } = useTranslate()
    const { i18n } = useTranslation()
    const { navigateRelative } = useNavigate()
    const { pages } = usePages()
    const { captureEventPosthog } = usePostHog()

    const group = useAppSelector(
        (store) => store.settingsApi.settings.response.group
    )

    const order = useAppSelector((store) => store.order)
    const orderNumber = useAppSelector(
        (store) =>
            store.returnsApi.order.response.order?.identification
                ?.customer_printed_order_id
    )
    const products = order.orderProducts

    const { callRefundMethods, loadingRefundMethods } = useRefundMethods(
        handleNavigateNextPage
    )
    const returnAddress = useAppSelector((store) => store.return.returnAddress)

    const date = useAppSelector(
        (store) => store.returnsApi.order.response.order?.purchased_at
    )
    const purchaseDate = date ? getDate(date, i18n.language) : ''

    const returnedItems = useAppSelector((store) => store.return.returnedItems)
    const returnedItemsCount = returnedItems.reduce((acc, item) => {
        if (item.item.action === RefundActions.ToReturn)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangedOneOnOneItemsCount = returnedItems.reduce((acc, item) => {
        if (item.action === ItemActions.ToExchangeOneOnOne)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangedFullCatalogItemsCount = returnedItems.reduce((acc, item) => {
        if (item.action === ItemActions.ToExchangeFullCatalog)
            return acc + item.item.quantity
        return acc
    }, 0)

    const onlyNotReceived = returnedItems.every(
        (retItem) => retItem.item.product_return_reason == 'NOT_RECEIVED'
    )
    const onlyExchanges = returnedItems.every(
        (item) =>
            item.action === ItemActions.ToExchangeOneOnOne ||
            item.action === ItemActions.ToExchangeFullCatalog
    )

    function handleNavigateNextPage() {
        if (group === 'twojeys') {
            const vetoReasons = ['DEFECTIVE', 'WRONG_ITEM']
            const isVeto = returnedItems.some((item) =>
                vetoReasons.includes(item.item.product_return_reason)
            )
            if (isVeto) {
                navigateRelative(pages.RefundMethod)
                return
            }
        }

        if (onlyNotReceived) {
            if (onlyExchanges) {
                navigateRelative(pages.ExchangeAddress)
            } else {
                navigateRelative(pages.RefundMethod)
            }
        } else {
            navigateRelative(pages.ReturnAddress)
        }
    }

    const handleClickContinue = () => {
        captureEventPosthog('Returned Items', {
            returnedItemsCount,
            exchangedOneOnOneItemsCount,
            exchangedFullCatalogItemsCount
        })
        callRefundMethods(returnAddress)
    }

    function isNonReturnableProduct(prod: Subproduct): boolean {
        const product = prod.product
        return (
            (product.non_returnable_items &&
                product.non_returnable_items.length > 0) ??
            false
        )
    }

    return (
        <>
            {loadingRefundMethods ? <LoadingModal loading /> : null}
            <TopBar>
                <h5
                    className="text-center"
                    dangerouslySetInnerHTML={{
                        __html: translate('item_selection_title')
                    }}
                />
                <ProgressBar page="order" />
            </TopBar>
            <MainDiv color={theme.colors.grey[5]}>
                <div className="w-10/12 max-w-[800px] md:w-8/12">
                    <div className="mt-4 md:mt-6">
                        <span
                            className="text-xs text-grey-2"
                            dangerouslySetInnerHTML={{
                                __html: translate('item_selection_order')
                            }}
                        />
                        <span className="text-xs text-common-black">
                            {orderNumber}
                        </span>
                    </div>
                    {purchaseDate ? (
                        <div>
                            <span
                                className="text-xs text-grey-2"
                                dangerouslySetInnerHTML={{
                                    __html: translate('item_selection_date')
                                }}
                            />
                            <span className="text-xs text-common-black">
                                {purchaseDate}
                            </span>
                        </div>
                    ) : null}
                    <div className="mt-4 flex flex-col gap-4 border-solid border-grey-3">
                        {products.map((prod, i) => (
                            <Fragment key={i}>
                                {isNonReturnableProduct(prod) ? (
                                    <NonReturnableProductCard
                                        product={prod.product}
                                    />
                                ) : (
                                    <OrderItem
                                        orderItem={prod.product}
                                        subProductId={prod.subProductId}
                                    />
                                )}
                            </Fragment>
                        ))}
                    </div>
                    <div className="mt-6 flex w-full justify-center">
                        <div className="w-full md:max-w-[280px]">
                            <Button
                                id="item-selection-continue-button"
                                onClick={handleClickContinue}
                                disabled={!returnedItems.length}
                                fullWidth
                            >
                                {translate('item_selection_button_continue')}
                                {returnedItemsCount > 0 &&
                                    ' (' + returnedItemsCount + ')'}
                            </Button>
                        </div>
                    </div>
                </div>
            </MainDiv>
        </>
    )
}

const TopBar = styled.div`
    z-index: 10;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin-top: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
`

const MainDiv = styled.div<{ color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    background-color: ${(p) => p.color};
`
