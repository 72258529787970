import { ReturnsExchangeOptionsResponseOneOnOneVariantInfo } from '@itsrever/rever-api'
import styled from 'styled-components'
import { useTranslate } from '@/hooks'
import { useTheme, Button } from '@itsrever/design-system'
import { device } from '@/utils'
import { useTranslation } from 'react-i18next'
import ProductPreview from '@/components/ProductPreview'
import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'

interface SelectExchangeOneOnOneProps {
    product: ModelsLineItem
    variants: ReturnsExchangeOptionsResponseOneOnOneVariantInfo[]
    selectedVariantId: string
    setSelectedVariantId: (variant: string) => void
    handleContinue: () => void
    handleClickFullCatalog: () => void
    isFullCatalog: boolean
    selectedQuantity: number
}

export const SelectExchangeOneOnOne: React.FC<SelectExchangeOneOnOneProps> = ({
    product,
    variants,
    selectedVariantId,
    setSelectedVariantId,
    handleContinue,
    handleClickFullCatalog,
    isFullCatalog,
    selectedQuantity
}) => {
    const { translate } = useTranslate()
    const { t } = useTranslation()

    const theme = useTheme()

    const hasStock = (
        variant: ReturnsExchangeOptionsResponseOneOnOneVariantInfo
    ) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity &&
                variant.inventory_quantity >= 1 &&
                selectedQuantity <= variant.inventory_quantity)
        )
    }

    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <div
                id="productPreview"
                className="mt-4 flex max-w-full border-b border-solid border-grey-3 py-4"
            >
                <ProductPreview product={product} />
            </div>
            <MainBox>
                <p
                    className="my-4 text-center font-bold"
                    dangerouslySetInnerHTML={{
                        __html: translate('select_exchange_title')
                    }}
                />
                <div className="flex w-full flex-col content-center items-center">
                    <div>
                        <div>
                            <CardsDiv>
                                {variants.map((v, i) => {
                                    if (!v.is_available || !hasStock(v)) {
                                        return (
                                            <VariantCard
                                                id={`variant-${i}`}
                                                borderColor={
                                                    theme.colors.grey[3]
                                                }
                                                key={i}
                                                disabled
                                            >
                                                <p className="w-full text-center text-grey-4">
                                                    {v.name}
                                                </p>
                                            </VariantCard>
                                        )
                                    } else {
                                        return (
                                            <VariantCard
                                                id={`variant-${i}`}
                                                hoverColor={
                                                    theme.colors.grey[5]
                                                }
                                                borderColor={
                                                    theme.colors.grey[2]
                                                }
                                                key={i}
                                                onClick={() =>
                                                    setSelectedVariantId(
                                                        v.platform_id ?? ''
                                                    )
                                                }
                                                style={
                                                    selectedVariantId ==
                                                    v.platform_id
                                                        ? {
                                                              backgroundColor:
                                                                  theme.colors
                                                                      .grey[4]
                                                          }
                                                        : {}
                                                }
                                            >
                                                <p className="w-full text-center">
                                                    {v.name}
                                                </p>
                                            </VariantCard>
                                        )
                                    }
                                })}
                            </CardsDiv>
                        </div>
                    </div>
                    <div className="mb-8 mt-4 flex w-full flex-col content-center items-center pb-2 lg:mt-8">
                        <div className="flex w-full flex-col gap-5 sm:w-fit">
                            <Button
                                id="continueExchange"
                                disabled={selectedVariantId === ''}
                                onClick={handleContinue}
                                fullWidth
                            >
                                {translate('select_exchange_button_continue')}
                            </Button>
                            {isFullCatalog ? (
                                <Button
                                    id="selectProductFullCatalog"
                                    onClick={handleClickFullCatalog}
                                    fullWidth
                                >
                                    {t('full_catalog.pick_another_product')}
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </MainBox>
        </div>
    )
}
const CardsDiv = styled.div`
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    max-width: 18rem;
`
interface VariantCardProps {
    disabled?: boolean
    hoverColor?: string
    borderColor: string
}

const VariantCard = styled.div<VariantCardProps>`
    margin-bottom: 0.5rem;
    min-width: 10rem;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid ${(p) => p.borderColor};
    border-radius: 6px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: ${(p) => (p.disabled ? 'auto' : 'pointer')};
    @media ${device.md} {
        &:hover {
            background-color: ${(p) =>
                p.disabled ? 'transparent' : p.hoverColor};
        }
    }
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
`

const MainBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
