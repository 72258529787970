import { useTranslate } from '@/hooks'
export interface FallbackNotFoundProps {
    title: string
}

export const FallbackNotFound: React.FC<FallbackNotFoundProps> = ({
    title
}) => {
    const { translate } = useTranslate()
    return (
        <div className="text-center">
            <h5>{title}</h5>
            <p
                className="my-6"
                dangerouslySetInnerHTML={{
                    __html: translate('fallback_not_found_try_again_or_contact')
                }}
            />
            <span className="ml-2" style={{ color: '#1b75eb' }}>
                : help@itsrever.com
            </span>
        </div>
    )
}

export default FallbackNotFound
