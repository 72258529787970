import ProductCard from '@/components/FullCatalog/Components/ProductCard'
import { useTranslation } from 'react-i18next'
import {
    formatProductPrice,
    variantsHaveSamePrices
} from '@/components/FullCatalog/utils'
import { useStoreMenu } from '@/hooks/useStoreMenu'
import { useStoreProducts } from '@/hooks/useStoreProducts'
import { Loading } from '@/components/Loading'

interface ProductSuggestionsProps {
    handleContinue: () => void
    handleClickFullCatalog: () => void
}

export const ProductSuggestions: React.FC<ProductSuggestionsProps> = ({
    handleContinue,
    handleClickFullCatalog
}) => {
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'

    const { menu, isMenuLoaded } = useStoreMenu()
    const { isLoadingProducts, randomProducts, blurryProducts } =
        useStoreProducts(menu, 0, '', isMenuLoaded)
    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <div className="h-fit min-h-[500px] w-full overflow-x-hidden px-2">
                <div>
                    <p className="mt-6 text-base text-slate-500">
                        {t('product_suggestions.title')}
                    </p>
                    <p className="text-lg font-bold text-slate-700">
                        {t('product_suggestions.subtitle')}
                    </p>
                </div>
                {isLoadingProducts ? (
                    <Loading loading />
                ) : (
                    <div className="my-5 grid max-w-[400px] grid-cols-2 gap-10">
                        {randomProducts.map((product, index) => {
                            return (
                                <ProductCard
                                    id={product.id ?? ''}
                                    key={index}
                                    onProductClick={() =>
                                        handleClickFullCatalog()
                                    }
                                    name={product.title ?? ''}
                                    imageUrl={product.featured_image_url ?? ''}
                                    description={product.description ?? ''}
                                    price={formatProductPrice(
                                        product.price_range?.min?.amount,
                                        product.price_range?.min?.currency,
                                        lang
                                    )}
                                    isFrom={
                                        !variantsHaveSamePrices(
                                            product?.price_range
                                        )
                                    }
                                />
                            )
                        })}

                        <div
                            className="flex cursor-pointer flex-col items-center"
                            onClick={() => handleClickFullCatalog()}
                        >
                            <div className="group relative mb-10 flex items-center justify-center rounded-lg font-bold text-slate-700">
                                <p className="absolute z-10 rounded-lg bg-white p-2 shadow-lg transition-all group-hover:scale-110">
                                    {t('product_suggestions.explore_more')}
                                </p>
                                <div className="grid grid-cols-2 gap-4">
                                    {blurryProducts.map((product, index) => (
                                        <img
                                            key={index}
                                            className="size-16 rounded-lg object-cover blur-sm transition-all group-hover:blur-none"
                                            src={product.featured_image_url}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mb-6 flex justify-center">
                    <span
                        className="cursor-pointer text-sm text-grey-3"
                        onClick={handleContinue}
                    >
                        {t('suggestions_by_reason.proceed')}
                    </span>
                </div>
            </div>
        </div>
    )
}
