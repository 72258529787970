import styled from 'styled-components'
import { device } from '@/utils'
import ProgressBarProcessed from '@/assets/images/tracking-progress-processed'
import ProgressBarTransit from '@/assets/images/tracking-progress-transit'
import ProgressBarWarehouse from '@/assets/images/tracking-progress-warehouse'
import ProgressBarReviewed from '@/assets/images/tracking-progress-reviewed'
import ProgressBarPending from '@/assets/images/tracking-progress-pending'
import { ReturnsTrackingInfoResponseStatusEnum } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'

interface ProgressBarProps {
    status: ReturnsTrackingInfoResponseStatusEnum
}

const TrackingProgressBar: React.FC<ProgressBarProps> = ({ status }) => {
    const { t } = useTranslation()
    const inProgressColor = '#edbb06'
    const finishedColor = '#5fbf74'
    const pendingColor = '#838383'
    return (
        <MainDiv>
            <p className="mt-1 inline-block  text-3xl font-bold text-brand-900">
                {t(`tracking_status.package_${status}`)}
            </p>
            <div className="mt-4 grid min-w-[280px] grid-cols-4 grid-rows-2 text-brand-900 ">
                <div data-testid="SVG" className="col-span-4 mb-1">
                    {status === 'STARTED' && (
                        <ProgressBarProcessed
                            pendingColor={inProgressColor}
                            successColor={finishedColor}
                        />
                    )}
                    {status === 'IN_TRANSIT' && (
                        <ProgressBarTransit
                            pendingColor={inProgressColor}
                            successColor={finishedColor}
                        />
                    )}
                    {status === 'IN_WAREHOUSE' && (
                        <ProgressBarWarehouse
                            pendingColor={inProgressColor}
                            successColor={finishedColor}
                        />
                    )}
                    {status === 'REVIEWED' && (
                        <ProgressBarReviewed successColor={finishedColor} />
                    )}
                    {status === 'AUTHORIZATION_PENDING' && (
                        <ProgressBarPending color={pendingColor} />
                    )}
                    {status === 'NOT_AUTHORIZED' && (
                        <ProgressBarPending color={pendingColor} />
                    )}
                    {status === 'DOCUMENT_UPLOAD_PENDING' && (
                        <ProgressBarPending color={pendingColor} />
                    )}
                    {status === 'CANCELED' && (
                        <ProgressBarPending color={pendingColor} />
                    )}
                </div>
                <CustomP>{t('tracking_status.STARTED')}</CustomP>
                <CustomP>{t('tracking_status.IN_TRANSIT')}</CustomP>
                <CustomP>{t('tracking_status.IN_WAREHOUSE')}</CustomP>
                <CustomP>{t('tracking_status.REVIEWED')}</CustomP>
            </div>
        </MainDiv>
    )
}

export default TrackingProgressBar

const MainDiv = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    z-index: 10;
`

const CustomP = styled.p`
    text-align: center;
    font-size: 11px;
    line-height: 14px;
    max-width: 70px;
    overflow-wrap: break-word;
    @media ${device.lg} {
        font-size: 14px;
        line-height: 18px;
    }
`
