import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import ProductPreview from '@/components/ProductPreview'
import styled from 'styled-components'
import { moreThan } from '@/utils'
import { useTranslate } from '@/hooks'
import { ReturnsNonReturnableItemReasonEnum } from '@itsrever/rever-api'

interface NonReturnableProductCardProps {
    product: ModelsLineItem
}

export const NonReturnableProductCard: React.FC<
    NonReturnableProductCardProps
> = ({ product }) => {
    const { translate } = useTranslate()

    function getEnumValueIndex(
        enumObj: Record<string, string>,
        targetValue: string
    ): number {
        return Object.values(enumObj).indexOf(targetValue)
    }

    function findReason() {
        if (
            product.non_returnable_items &&
            product.non_returnable_items.length > 0
        ) {
            const reasonIndex = getEnumValueIndex(
                ReturnsNonReturnableItemReasonEnum,
                product.non_returnable_items[0].reason ?? ''
            )
            return translate(`item_selection_reason${reasonIndex}`)
        }
        return 'Non returnable'
    }
    const nonReturnableReason = findReason()

    return (
        <Card>
            <ProductPreview product={product} disabled />
            <p
                className="mr-6 mt-2 text-right text-xs text-error-main"
                dangerouslySetInnerHTML={{
                    __html: nonReturnableReason
                }}
            />
        </Card>
    )
}

const Card = styled.div`
    padding: 1rem 0;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    @media ${moreThan.xl} {
        padding: 2rem 0 1rem 0;
    }
`
