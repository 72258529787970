import styled from 'styled-components'
import { device } from '@/utils'
import { useTheme } from '@itsrever/design-system'
import PlaceHolder from '@/assets/images/product_placeholder.png'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ProductCardProps {
    name: string
    id: string
    description?: string
    isFrom?: boolean
    creditPrice?: string
    price?: string
    imageUrl?: string
    secondaryImageUrl?: string
    onProductClick?: () => void
}

export const ProductCard: React.FC<ProductCardProps> = ({
    name,
    id,
    description,
    creditPrice,
    price,
    isFrom = false,
    imageUrl,
    secondaryImageUrl,
    onProductClick
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const [isProductHover, setIsProductHover] = useState(false)
    const defaultImage = imageUrl ? imageUrl : PlaceHolder
    const secondaryImage = secondaryImageUrl
    const image =
        isProductHover && secondaryImage ? secondaryImage : defaultImage

    return (
        <Card
            id={'product-' + id}
            className="group transition-all ease-in-out md:hover:shadow-lg"
            onClick={() => {
                if (onProductClick) onProductClick()
            }}
            $color={
                theme.colors.primary.main === 'transparent'
                    ? '#000'
                    : theme.colors.primary.main
            }
        >
            <div className="overflow-hidden rounded-lg md:rounded-none md:rounded-t-lg md:hover:rounded-t-lg">
                <ProductImage
                    src={image}
                    className="transition-all duration-[450ms] ease-in-out group-hover:scale-105"
                    loading="lazy"
                    onMouseEnter={() => setIsProductHover(true)}
                    onMouseLeave={() => setIsProductHover(false)}
                />
            </div>
            <div className="flex flex-col gap-2 px-4 py-3">
                <div>
                    <p>{name}</p>
                    <p
                        className="text-xs"
                        style={{ color: theme.colors.grey[1] }}
                    >
                        {description}
                    </p>
                    {creditPrice ? (
                        <div className="mt-2 flex flex-wrap items-center gap-1">
                            <div
                                style={{
                                    boxShadow: '0px 0px 5.7px 0px #FFF inset'
                                }}
                                className="-ml-1 w-fit shrink-0 rounded-full bg-radial-gradient from-brand-800 to-brand-700 px-3 py-0.5 shadow shadow-brand-300"
                            >
                                <p className="text-sm text-white drop-shadow-sm">
                                    {creditPrice}
                                </p>
                            </div>
                            <p className="flex shrink-0 text-xs font-light text-neutral-400">
                                {price} original price
                            </p>
                        </div>
                    ) : (
                        <>
                            {price ? (
                                <p className="flex font-light text-neutral-400">
                                    {isFrom ? (
                                        <span className="mr-1 text-neutral-400">
                                            {t('full_catalog.from')}
                                        </span>
                                    ) : null}
                                    {price}
                                </p>
                            ) : null}
                        </>
                    )}
                </div>
            </div>
        </Card>
    )
}

export default ProductCard

interface CardProps {
    $color: string
}

const Card = styled.div<CardProps>`
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
    cursor: pointer;
    &:hover {
    }
    @media ${device.md} {
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1),
            0 1px 2px -1px rgb(0 0 0 / 0.1);
    }
`

const ProductImage = styled.img`
    aspect-ratio: 4 / 5;
    height: auto;
    width: 100%;
    object-fit: cover;
`
