import { useTranslate } from '@/hooks'
import styled from 'styled-components'
import { Button, ReverLoading } from '@itsrever/design-system'
import ProductPreview from '@/components/ProductPreview'
import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import { ReturnsExchangeOptionsResponse } from '@itsrever/rever-api'
import { FlagrEvalPost } from '@/services/Flagr.api'
import { useAppSelector } from '@/redux/hooks'
import { useEffect, useState } from 'react'

interface InitialProps {
    product: ModelsLineItem
    handleClickExchange: () => void
    handleClickRefund: () => void
    handleClickFullCatalog: () => void
    exchangeOptions: ReturnsExchangeOptionsResponse
    loading: boolean
}

export const Initial: React.FC<InitialProps> = ({
    product,
    handleClickExchange,
    handleClickRefund,
    handleClickFullCatalog,
    exchangeOptions,
    loading
}) => {
    const { translate } = useTranslate()
    const ecommerceId = useAppSelector(
        (store) => store.settingsApi.settings.response?.ecommerce_id
    )
    const group = useAppSelector((s) => s.settingsApi.settings.response.group)
    const isTropicfeel = group === 'tropicfeel'

    const isAvailableForExchange =
        (exchangeOptions?.exchange_options?.exchange_flow ===
            'EXCHANGE_FLOW_OPEN' ||
            exchangeOptions.exchange_options?.exchange_flow ===
                'EXCHANGE_FLOW_OPEN_WITH_GIFT_CARD' ||
            exchangeOptions?.line_item_product_info?.variants?.some(
                (variant) => {
                    return (
                        variant.is_available &&
                        (!variant.is_inventory_enabled ||
                            (variant.inventory_quantity !== undefined &&
                                variant.inventory_quantity > 0))
                    )
                }
            )) ??
        false

    const isFullCatalog =
        exchangeOptions?.exchange_options?.exchange_flow ===
        'EXCHANGE_FLOW_FULL'

    const returnAvailable =
        exchangeOptions.exchange_options?.force_exchange !== true

    const [showRefundFlow, setShowRefundFlow] = useState(false)

    useEffect(() => {
        FlagrEvalPost({
            flagID: 1486,
            entityContext: { ecommerceId }
        }).then((res) => {
            if (res.variantKey === 'true') {
                setShowRefundFlow(true)
            }
        })
    }, [ecommerceId])

    if (loading)
        return (
            <div className="flex h-fit w-full items-center justify-center py-10">
                <ReverLoading />
            </div>
        )

    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <p className="mt-4 px-4 text-center md:px-6">
                <b
                    dangerouslySetInnerHTML={{
                        __html: showRefundFlow
                            ? translate('item_modal_refund_flow_title')
                            : translate('item_modal_moderate_title')
                    }}
                />
            </p>
            <div
                id="productPreview"
                className="mt-4 flex max-w-full border-b border-solid border-grey-3 py-4"
            >
                <ProductPreview product={product} />
            </div>

            {showRefundFlow ? (
                <ModerateDiv>
                    {returnAvailable && (
                        <div>
                            <Button fullWidth onClick={handleClickRefund}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            'item_modal_refund_flow_refund_card'
                                        )
                                    }}
                                />
                            </Button>
                        </div>
                    )}

                    {isAvailableForExchange && !isTropicfeel ? (
                        <p
                            id="exchangeButton"
                            className="mb-8 mt-4 cursor-pointer text-grey-1 underline underline-offset-4"
                            onClick={handleClickExchange}
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    'item_modal_refund_flow_exchange_card'
                                )
                            }}
                        />
                    ) : null}
                    {isFullCatalog ? (
                        <p
                            id="exploreButton"
                            className="mb-8 mt-4 cursor-pointer text-grey-1 underline underline-offset-4"
                            onClick={handleClickFullCatalog}
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    'item_modal_refund_flow_fullcatalog_card'
                                )
                            }}
                        />
                    ) : null}
                </ModerateDiv>
            ) : (
                <ModerateDiv>
                    {isAvailableForExchange && !isTropicfeel && (
                        <div>
                            <Button
                                fullWidth
                                onClick={handleClickExchange}
                                id="exchangeButton"
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            'item_modal_moderate_exchange'
                                        )
                                    }}
                                />
                            </Button>
                        </div>
                    )}

                    {isFullCatalog ? (
                        <div className="mt-5">
                            <Button
                                fullWidth
                                onClick={handleClickFullCatalog}
                                id="exploreButton"
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            'change_product_full_catalog'
                                        )
                                    }}
                                />
                            </Button>
                        </div>
                    ) : null}

                    {returnAvailable ? (
                        <p
                            className="mb-8 mt-4 cursor-pointer text-grey-1 underline underline-offset-4"
                            onClick={handleClickRefund}
                            dangerouslySetInnerHTML={{
                                __html: translate('item_modal_moderate_refund')
                            }}
                        />
                    ) : null}
                </ModerateDiv>
            )}
        </div>
    )
}

const ModerateDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin-top: 2rem;
    margin-bottom: 1rem;
`
