import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useEffect } from 'react'
import { fetchMenu } from '@/redux/api/storeApiSlice'
import { StoreApiFetchMenuRequest } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'

export function useStoreMenu() {
    const dispatch = useAppDispatch()
    const { i18n } = useTranslation()
    const countryCode =
        useAppSelector((store) => store.return.returnAddress.country_code) ??
        'ES'
    const language = i18n.language.toLocaleUpperCase() ?? 'EN'
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceId = settings.ecommerce_id ?? ''
    const menuCall = useAppSelector((store) => store.store.menu)
    const menu = menuCall.response
    const loadingMenu = menuCall.loading

    useEffect(() => {
        const fetchStoreMenu = async () => {
            const fetchMenuParams: StoreApiFetchMenuRequest = {
                ecommerceId,
                language,
                countryCode
            }
            dispatch(fetchMenu(fetchMenuParams))
        }
        if (loadingMenu === 'succeeded' || loadingMenu === 'failed') return
        fetchStoreMenu()
    }, [dispatch, language, countryCode, ecommerceId, loadingMenu])

    return { menu, loadingMenu, isMenuLoaded: loadingMenu === 'succeeded' }
}
