import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import {
    useTheme,
    CoinIcon,
    RepeatIcon,
    CrossIcon
} from '@itsrever/design-system'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useState, useEffect } from 'react'
import ProductPreview from '../ProductPreview'
import {
    ItemActions,
    RefundActions,
    removeReturnedItem
} from '@/redux/return/returnSlice'
import styled from 'styled-components'
import { device } from '@/utils'
import { useNavigate, usePages, useTranslate } from '@/hooks'
import { FlowManager } from '@/components/ItemSelectionComponents'
import { SelectedExchangeItems } from './SelectedExchangeItems'
import { useUpdateCart } from '@/application/use-update-cart'

export type FlowSteps =
    | 'INITIAL'
    | 'SELECT_REASON'
    | 'SELECT_EXCHANGE_ONE_ON_ONE'
    | 'SELECT_EXCHANGE_OPEN'
    | 'FULL_CATALOG'
    | 'SUGGESTION_BY_REASON'
    | 'PRODUCT_SUGGESTIONS'

export interface OrderItemProps {
    orderItem: ModelsLineItem
    subProductId: number
}

export const OrderItem: React.FC<OrderItemProps> = ({
    orderItem,
    subProductId
}) => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const urlLineItemId = urlParams.get('lineItemId')

    const { translate } = useTranslate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const { clearReturnedItemCartItems } = useUpdateCart()
    const { pages, currentPage } = usePages()
    const { navigateRelative } = useNavigate()

    const returnProgress = useAppSelector((store) => store.return)
    const returnedItems = returnProgress.returnedItems
    const returnedItem = returnedItems.find(
        (retItem) =>
            retItem.item.line_item_id === orderItem.id &&
            retItem.subProductId === subProductId
    )
    const quantity = returnedItem?.item.quantity
    const isExchanged = returnedItem?.item.action === RefundActions.ToExchange
    const isExchangeFullCatalog =
        returnedItem?.action === ItemActions.ToExchangeFullCatalog
    const isSelected = returnedItem ? true : false

    const newItems = useAppSelector((store) => store.return.newItems)?.filter(
        (item) => item.returnedItemId === orderItem.id
    )

    const [isSelectionFlowOpen, setIsSelectionFlowOpen] = useState(false)
    const [defaultStep, setDefaultStep] = useState<FlowSteps>()
    const [showFullCatalogCart, setShowFullCatalogCart] = useState(false)

    const handleClickCross = () => {
        dispatch(
            removeReturnedItem({
                lineItemId: orderItem.id ?? ''
            })
        )
        setDefaultStep('SELECT_REASON')
        setShowFullCatalogCart(false)
        clearReturnedItemCartItems(orderItem.id ?? '')
    }

    const handleClose = () => {
        if (isExchangeFullCatalog && newItems.length === 0) {
            dispatch(
                removeReturnedItem({
                    lineItemId: returnedItem.lineItemId
                })
            )
        }
        setDefaultStep('SELECT_REASON')
        setShowFullCatalogCart(false)
        closeSelectionFlow()
    }

    const handleClickCard = () => {
        if (isSelected) return
        openSelectionFlow()
    }

    const onClickExchangeItems = () => {
        if (isExchangeFullCatalog) {
            setShowFullCatalogCart(true)
            setDefaultStep('FULL_CATALOG')
            openSelectionFlow()
        }
    }
    const openSelectionFlow = () => {
        setIsSelectionFlowOpen(true)
        navigateRelative(pages.ItemOptions, { lineItemId: orderItem.id ?? '' })
    }
    const closeSelectionFlow = () => {
        setIsSelectionFlowOpen(false)
        navigateRelative(pages.ItemSelection)
    }

    useEffect(() => {
        if (currentPage === 'ItemOptions' && urlLineItemId === orderItem.id) {
            setIsSelectionFlowOpen(true)
        } else if (currentPage === 'ItemSelection' && isSelectionFlowOpen) {
            handleClose()
        }
    }, [currentPage])

    return (
        <>
            <Card
                selected={isSelected}
                borderColor={theme.colors.grey[2]}
                onClick={handleClickCard}
            >
                <ProductPreview
                    product={orderItem}
                    selectedQuantity={isSelected ? quantity : undefined}
                />

                {isSelected ? (
                    <>
                        <SelectedBox>
                            {isExchanged ? (
                                <div
                                    className={`flex shrink items-center transition-all ${
                                        isExchangeFullCatalog
                                            ? 'mt-2 cursor-pointer rounded-lg px-2 py-1 hover:bg-neutral-100 lg:mt-0 '
                                            : ''
                                    }`}
                                    onClick={() => onClickExchangeItems()}
                                >
                                    <ExchangeVariantText
                                        className="mr-2 flex-1 text-xs lg:text-sm"
                                        dangerouslySetInnerHTML={{
                                            __html: isExchangeFullCatalog
                                                ? orderItem.name ?? ''
                                                : orderItem.variant_name ?? ''
                                        }}
                                    />
                                    <RepeatIcon
                                        color={theme.colors.common.black}
                                    />
                                    {isExchangeFullCatalog ? (
                                        <div className="mx-3">
                                            <SelectedExchangeItems
                                                selectedItems={newItems}
                                            />
                                        </div>
                                    ) : (
                                        <ExchangeVariantText className="ml-2 text-xs lg:text-sm">
                                            {newItems[0]?.productVariant
                                                .variantName ?? ''}
                                        </ExchangeVariantText>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <ExchangeVariantText
                                        className="mr-2 text-xs lg:text-sm"
                                        dangerouslySetInnerHTML={{
                                            __html: translate('icons_return')
                                        }}
                                    />
                                    <CoinIcon
                                        color={theme.colors.common.black}
                                    />
                                </>
                            )}
                        </SelectedBox>

                        <CrossBox
                            id="crossbox"
                            color={theme.colors.grey[3]}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleClickCross()
                            }}
                        >
                            <CrossIcon color={theme.colors.grey[1]} />
                        </CrossBox>
                    </>
                ) : null}
            </Card>

            {isSelectionFlowOpen ? (
                <FlowManager
                    orderItem={orderItem}
                    subProductId={subProductId}
                    isOpen={isSelectionFlowOpen}
                    handleClose={handleClose}
                    defaultStep={defaultStep}
                    showFullCatalogCart={showFullCatalogCart}
                    setShowFullCatalogCart={setShowFullCatalogCart}
                />
            ) : null}
        </>
    )
}

const ExchangeVariantText = styled.span`
    /* max-width: 45%; */
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.15rem;
    border-radius: 0.25rem;
`

const SelectedBox = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-grow: 1;

    @media (max-width: 899px) {
        padding: 0rem 1rem 0rem 1rem;
    }
    @media ${device.lg} {
        width: 30%;
        align-items: center;
        align-content: center;
        height: fit-content;
    }
`

const Card = styled.div<{ selected: boolean; borderColor: string }>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem 0;
    width: 100%;
    border: 1px solid;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-color: ${(p) => (p.selected ? p.borderColor : 'transparent')};
    background-color: white;
    cursor: ${(p) => (p.selected ? 'auto' : 'pointer')};
    @media ${device.lg} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media ${device.xl} {
        padding: 2rem 0;
    }
`

interface CrossBoxProps {
    color: string
}

const CrossBox = styled.div<CrossBoxProps>`
    height: fit-content;
    // Below 900px
    @media (max-width: 899px) {
        position: absolute;
        right: 0px;
        top: 0px;
        margin-right: 1rem;
        margin-top: 1rem;
    }

    cursor: pointer;
    &:hover {
        background-color: ${(p) => p.color};
    }
    // Over lg
    @media ${device.lg} {
        padding: 0.375rem;
        margin-right: 2rem;
        margin-left: 0.5rem;
        border-radius: 9999px;
    }
`
