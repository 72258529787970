import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { LessIcon, MoreIcon, useTheme } from '@itsrever/design-system'

interface QuantitySelectorProps {
    value?: number
    min?: number
    max?: number
    onUpdate?: (value: number) => void
    disabled?: boolean
}

const QuantitySelector: React.FC<QuantitySelectorProps> = ({
    value,
    min = 0,
    max,
    onUpdate,
    disabled = false
}) => {
    const [internalCount, setInternalCount] = useState(min ?? 0)
    const [direction, setDirection] = useState(0)
    const theme = useTheme()

    const isControlled = value !== undefined
    const count = isControlled ? value : internalCount

    useEffect(() => {
        if (!isControlled) {
            setInternalCount(min ?? 0)
        }
    }, [min, isControlled])

    const increment = () => {
        if (max !== undefined && count >= max) return
        setDirection(1)
        if (!isControlled) {
            setInternalCount(count + 1)
        }
        onUpdate && onUpdate(count + 1)
    }

    const decrement = () => {
        if (min !== undefined && count <= min) return
        setDirection(-1)
        if (!isControlled) {
            setInternalCount(count - 1)
        }
        onUpdate && onUpdate(count - 1)
    }

    const variants = {
        enter: (direction: number) => ({
            y: -direction * 50,
            opacity: 0
        }),
        center: {
            y: 0,
            opacity: 1
        },
        exit: (direction: number) => ({
            y: direction * 50,
            opacity: 0
        })
    }

    return (
        <div className="flex items-center justify-center space-x-4 text-2xl">
            <button
                onClick={decrement}
                className="rounded text-xl"
                disabled={disabled || (count <= min ? true : false)}
            >
                <LessIcon
                    color={
                        disabled || (count <= min ? true : false)
                            ? theme.colors.grey[4]
                            : theme.colors.common.black
                    }
                />
            </button>
            <div className="flex w-2 items-center justify-center overflow-hidden">
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={count}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ duration: 0.2 }}
                        className="absolute mx-2"
                    >
                        {count}
                    </motion.div>
                </AnimatePresence>
            </div>
            <button
                onClick={increment}
                className="rounded text-xl"
                disabled={
                    disabled ||
                    (max !== undefined && count >= max ? true : false)
                }
            >
                <MoreIcon
                    color={
                        disabled ||
                        (max !== undefined && count >= max ? true : false)
                            ? theme.colors.grey[4]
                            : theme.colors.common.black
                    }
                />
            </button>
        </div>
    )
}

export default QuantitySelector
