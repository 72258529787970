import { QuantitySelector } from '@/components/QuantitySelector'
import { NewItem } from '@/domain/new-item'
import { useUpdateCart } from '@/application/use-update-cart'
import { DeleteIcon } from '@itsrever/design-system'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { removeReturnedItem } from '@/redux/return/returnSlice'
import { usePostHog } from '@/application/use-posthog'

interface CartItemProps {
    item: NewItem
    index: number
    returningItemId: string
}

export const CartItem: React.FC<CartItemProps> = ({
    item,
    index,
    returningItemId
}) => {
    const dispatch = useAppDispatch()
    const { captureEventPosthog } = usePostHog()

    const variant = item.productVariant
    const { removeCartItem, updateCartItemQuantity } = useUpdateCart()
    const newItems = useAppSelector((state) => state.return.newItems)
    const returnedItems = useAppSelector((state) => state.return.returnedItems)

    const handleClickRemove = () => {
        captureEventPosthog('FC - Item deleted from cart')
        removeCartItem(item)
        if (item.returnedItemId !== returningItemId) {
            const associatedItems = newItems.filter(
                (i) => i.returnedItemId === item.returnedItemId
            )
            if (associatedItems.length <= 1) {
                const returnedItem = returnedItems.find(
                    (returnedItem) =>
                        returnedItem.lineItemId === item.returnedItemId
                )
                if (returnedItem) {
                    dispatch(
                        removeReturnedItem({
                            lineItemId: returnedItem.lineItemId
                        })
                    )
                }
            }
        }
    }
    const onUpdateCounter = (newQuantity: number) => {
        updateCartItemQuantity(item, newQuantity)
    }
    return (
        <div className="flex items-center rounded-lg bg-white py-1 shadow-sm md:py-0">
            <img
                src={variant.imageUrl}
                alt={variant.variantName}
                className={`mr-2 size-16 ${
                    index % 2 === 0 ? 'rotate-3' : '-rotate-3'
                } scale-110 rounded-xl object-cover shadow-lg transition-all hover:rotate-0`}
            />

            <div className="flex w-full items-center justify-between gap-4 px-2">
                <div>
                    <h3 className="mb-1 flex items-center gap-2 text-base font-semibold text-neutral-900">
                        <span>{variant.productName}</span>
                    </h3>
                    <p className="font-light text-neutral-600">
                        {variant.variantName}
                    </p>
                </div>
                <div className="mr-1 flex gap-3">
                    <QuantitySelector
                        value={item.quantity}
                        onUpdate={onUpdateCounter}
                        min={1}
                        max={item.productVariant.inventoryQuantity ?? 1}
                    />
                    <button
                        onClick={handleClickRemove}
                        className="flex items-center justify-center rounded p-1 text-neutral-400 hover:bg-neutral-100"
                    >
                        <DeleteIcon scale={1.1} />
                    </button>
                </div>
            </div>
        </div>
    )
}
