import { Button, CrossIcon } from '@itsrever/design-system'
import {
    ReturnsProductDetailsResponse,
    ReturnsProductDetailsResponseVariantInfo
} from '@itsrever/rever-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    formatProductPrice,
    getGridPosition
} from '@/components/FullCatalog/utils'
import { QuantitySelector } from '@/components/QuantitySelector'
import { animateCartItemAddition } from '@/components/FullCatalog/Components/Animations'
import { useUpdateCart } from '@/application/use-update-cart'
import { DesktopVariantButton } from './DesktopVariantButton'
import { useCredit } from '@/hooks'
import { usePostHog } from '@/application/use-posthog'

interface DesktopVariantSelectorProps {
    productId: string
    selectedCategoryIndex: number
    returnedItemId: string
    name: string
    description?: string
    product: ReturnsProductDetailsResponse
    onClose: () => void
    photoList: Array<string>
    mainImage: string
    loadingDetails: boolean
}

export const DesktopVariantSelector: React.FC<DesktopVariantSelectorProps> = ({
    productId,
    selectedCategoryIndex,
    returnedItemId,
    name,
    description,
    product,
    onClose,
    photoList,
    mainImage,
    loadingDetails
}) => {
    const { t, i18n } = useTranslation()
    const { addItemToCart } = useUpdateCart()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
    const { captureEventPosthog } = usePostHog()

    const [selectedQuantity, setSelectedQuantity] = useState<number>(1)
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>()
    const [displayImage, setDisplayImage] = useState<string>(
        mainImage
            ? mainImage
            : product.product_info?.featured_image_url ?? photoList.length
            ? photoList[0]
            : ''
    )
    const variants = product.product_info?.variants ?? []
    const { totalAvailableCredit, spentCredit } = useCredit()
    const remainingCredit = totalAvailableCredit - spentCredit

    const maxQuantity =
        selectedVariantIndex !== undefined
            ? variants[selectedVariantIndex]?.inventory_quantity ?? 1
            : 1

    const onChangeQuantity = (newQuantity: number) => {
        setSelectedQuantity(newQuantity)
    }

    const changeMainImage = (newImage: string) => {
        setDisplayImage(
            newImage ?? product.product_info?.featured_image_url ?? ''
        )
    }

    const onVariantClick = (
        variant: ReturnsProductDetailsResponseVariantInfo,
        index: number
    ) => {
        if (!hasStock(variant)) return
        changeMainImage((variant.image_urls && variant.image_urls[0]) ?? '')
        if (selectedVariantIndex === index) {
            setSelectedVariantIndex(undefined)
        } else {
            setSelectedVariantIndex(index)
        }
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant?.inventory_quantity !== undefined &&
                variant?.inventory_quantity >= 1)
        )
    }

    const sortByStock = (
        a: ReturnsProductDetailsResponseVariantInfo,
        b: ReturnsProductDetailsResponseVariantInfo
    ) => {
        const aHasStock = hasStock(a)
        const bHasStock = hasStock(b)

        if (aHasStock && !bHasStock) {
            return -1
        } else if (!aHasStock && bHasStock) {
            return 1
        } else {
            return 0
        }
    }
    const sortedVariants = [...variants].sort(sortByStock)

    const onConfirmVariant = async () => {
        await animateCartItemAddition()
        const gridPosition = getGridPosition(productId)
        captureEventPosthog('FC - Product added to cart', {
            row: gridPosition?.row,
            col: gridPosition?.col,
            categoryIndex: selectedCategoryIndex
        })

        const selectedSortedVariant = sortedVariants[selectedVariantIndex ?? 0]
        const variantIndex = variants.findIndex(
            (variant) => variant === selectedSortedVariant
        )

        addItemToCart(
            returnedItemId,
            selectedQuantity,
            variantIndex ?? 0,
            product
        )
        onClose()
    }

    const computeVariantPrice = (
        variant: ReturnsProductDetailsResponseVariantInfo
    ) => {
        return formatProductPrice(
            variant.price?.amount,
            variant.price?.currency_code,
            lang
        )
    }
    const computeVariantCreditPrice = (
        variant: ReturnsProductDetailsResponseVariantInfo
    ) => {
        const amountValue = variant.price?.amount ?? '0'
        const parsedAmount = parseFloat(amountValue)
        if (remainingCredit <= 0) {
            return ''
        }
        const restingAmount = remainingCredit < 0 ? 0 : remainingCredit
        const creditValue = parsedAmount - restingAmount
        const value = creditValue < 0 ? 0 : creditValue
        return formatProductPrice(
            value.toString(),
            variant.price?.currency_code,
            lang
        )
    }

    return (
        <div className="mx-auto flex max-h-[550px] w-full max-w-screen-xl justify-center gap-8 rounded-lg bg-white p-12">
            <button
                onClick={onClose}
                className="absolute right-3 top-3 flex size-8 shrink-0 cursor-pointer items-center justify-center rounded bg-gray-100 text-xl text-gray-600 hover:bg-gray-200"
            >
                <CrossIcon />
            </button>
            <div className="flex gap-2">
                <div className="flex w-24 flex-col gap-2 overflow-y-scroll">
                    {loadingDetails
                        ? [...Array(8)].map((value, i) => {
                              return (
                                  <div
                                      className="relative w-full overflow-hidden rounded-lg bg-neutral-200 pb-[100%]"
                                      key={i}
                                  />
                              )
                          })
                        : photoList.map((photo, i) => {
                              return (
                                  <div
                                      className="relative w-full overflow-hidden rounded-lg bg-neutral-200 bg-cover bg-center bg-no-repeat pb-[100%]"
                                      key={i}
                                      style={{
                                          backgroundImage: `url(${photo})`
                                      }}
                                      onMouseEnter={() =>
                                          changeMainImage(photo)
                                      }
                                  />
                              )
                          })}
                </div>

                <img
                    id={'main-image'}
                    src={displayImage}
                    className="aspect-[2/3] max-h-[30rem] w-full min-w-20 shrink rounded-lg object-cover"
                />
            </div>
            <div className="relative flex min-w-60 flex-1 flex-col">
                <h5>{name}</h5>
                <p>{description}</p>
                <hr className="my-2" />
                <p className="mb-4 mt-2">{t('full_catalog.select_option')}</p>
                <div className="flex grow flex-col overflow-scroll">
                    {loadingDetails
                        ? [...Array(5)].map((_, index) => (
                              <div
                                  className="mb-2 h-24 w-full rounded border border-solid border-neutral-200 bg-neutral-50 py-1 pl-4 pr-2"
                                  key={index}
                              />
                          ))
                        : sortedVariants.map((variant, i) => {
                              return (
                                  <DesktopVariantButton
                                      key={i}
                                      index={i}
                                      variant={variant}
                                      productName={name ?? ''}
                                      hasStock={hasStock(variant)}
                                      onVariantClick={onVariantClick}
                                      selectedVariantIndex={
                                          selectedVariantIndex
                                      }
                                      price={computeVariantPrice(variant)}
                                      creditPrice={computeVariantCreditPrice(
                                          variant
                                      )}
                                  />
                              )
                          })}
                </div>
                <div className="mt-3 flex w-full items-center justify-center">
                    <div className="mr-4">
                        <QuantitySelector
                            disabled={selectedVariantIndex === undefined}
                            onUpdate={onChangeQuantity}
                            min={1}
                            max={maxQuantity}
                        />
                    </div>
                    <Button
                        disabled={selectedVariantIndex === undefined}
                        onClick={onConfirmVariant}
                    >
                        {t('full_catalog.add_to_cart')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
